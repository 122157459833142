<template>
	<div class="modal-body form form-horizontal">
		<!-- CREAR -->
		<div v-if="propInfo.data.method == 'definition'" class="row">
			<!-- DATOS DEL POSTULANTE -->
			<div class="col-md-12">
				<h5>Datos del postulante</h5>
			</div>
			<!-- TIPO DE DOC. -->
			<div class="col-md-6">
				<label class="col-form-label label-modal" for="business_id">Tipo de Doc.</label>
				<p class="mb-0">{{ propInfo.data.row.postulant_type_document }}</p>
			</div>
			<!-- NUMERO DE DOCUMENTO -->
			<div class="col-md-6">
				<label class="col-form-label label-modal">Número de Doc.</label>
				<p class="mb-0">{{ propInfo.data.row.postulant_document }}</p>
			</div>
			<!-- NOMBRES COMPLETOS -->
			<div class="col-md-12">
				<label class="col-form-label label-modal">Nombre</label>
				<p class="mb-0">{{ propInfo.data.row.postulant_names }}</p>
			</div>
			<!-- CELULAR -->
			<div class="col-md-6">
				<label class="col-form-label label-modal">Celular</label>
				<p class="mb-0">{{ propInfo.data.row.postulant_cellphone }}</p>
			</div>
			<!-- EMAIL -->
			<div class="col-md-6">
				<label class="col-form-label label-modal">Correo</label>
				<p class="mb-0">{{ propInfo.data.row.postulant_email }}</p>
			</div>
			<!------------ DATOS DEL EXAMEN ------------->
			<div class="col-md-12 mt-3">
				<h5>Datos del examen medico</h5>
			</div>
			<div class="col-md-6">
				<label class="col-form-label label-modal">Tipo de examen</label>
				<p class="mb-0">{{ propInfo.data.row.exam_type_name }}</p>
			</div>
			<div class="col-md-6">
				<label class="col-form-label label-modal">Centro medico</label>
				<p class="mb-0">{{ propInfo.data.row.place_name }}</p>
			</div>
			<div class="col-md-6">
				<label class="col-form-label label-modal">Fecha de la cita</label>
				<p class="mb-0">{{ propInfo.data.row.scheduled_to }}</p>
			</div>
			<div class="col-md-6">
				<label class="col-form-label label-modal">Prueba antigena</label>
				<p class="mb-0">{{ propInfo.data.row.antigen ? 'Si' : 'No' }}</p>
			</div>
			<!----------- RESULTADOS ------->
			<div class="col-md-12 mt-3">
				<h5>Ingreso de resultados</h5>
			</div>
			<!-- ESTADO -->
			<div class="col-md-4 form-group">
				<label class="col-form-label label-modal">Estado</label><br />
				<template v-if="propInfo.data.row.state">
					<span v-if="propInfo.data.row.suitable == 1" class="badge badge-success">Apto</span>
					<span v-if="propInfo.data.row.suitable == 2" class="badge badge-warning">Apto con recomendaciones</span>
					<span v-if="propInfo.data.row.suitable == 0" class="badge badge-danger">No apto</span>
				</template>
				<select v-else v-model="state" class="form-control form-control-sm" name="state" v-validate="'required'" data-vv-as="'Estado'">
					<option :value="null">Seleccionar</option>
					<option value="1">Apto</option>
					<option value="0">No apto</option>
					<option value="2">Apto pero con recomendaciones</option>
				</select>
				<span v-show="errors.has('state')" class="form-group__error"><i class="fas fa-exclamation-circle"></i> {{ errors.first('state') }} </span>
			</div>
			<div class="col-md-8 form-group">
				<label class="col-form-label label-modal d-block">Certificado</label>
				<button @click="mtd_donload" v-if="propInfo.data.row.state" class="btn btn-xs btn-primary"><i class="fas fa-file-pdf"></i> Descargar</button>
				<input
					v-else
					class="form-control form-control-sm"
					@change="handleFileUpload()"
					type="file"
					name="file"
					id="file"
					ref="file"
					v-validate="'required|ext:pdf'"
					data-vv-as="'Certificado'" />
				<span v-show="errors.has('file')" class="form-group__error"><i class="fas fa-exclamation-circle"></i> {{ errors.first('file') }} </span>
			</div>
			<div class="col-md-4 form-group" v-if="propInfo.data.row.antigen">
				<label class="col-form-label label-modal">Resultado Prueba antigena</label><br />
				<template v-if="propInfo.data.row.state">
					<span v-if="propInfo.data.row.state_antigena == 1" class="badge badge-danger">Positivo</span>
					<span v-if="propInfo.data.row.state_antigena == 0" class="badge badge-success">Negativo</span>
				</template>
				<select v-else v-model="state_antigena" class="form-control form-control-sm" name="state" v-validate="'required'" data-vv-as="'Estado'">
					<option :value="null">Seleccionar</option>
					<option value="1">Positivo</option>
					<option value="0">Negativo</option>
				</select>
				<span v-show="errors.has('state')" class="form-group__error"><i class="fas fa-exclamation-circle"></i> {{ errors.first('state') }} </span>
			</div>
			<!--CERTIFICADO ANTIGENO -->
			<div class="col-md-8 form-group" v-if="propInfo.data.row.antigen">
				<label class="col-form-label label-modal d-block">Certificado de Prueba antigena</label>
				<button @click="mtd_donload_antigena" v-if="propInfo.data.row.state" class="btn btn-xs btn-primary"><i class="fas fa-file-pdf"></i> Descargar</button>
				<input
					v-else
					class="form-control form-control-sm"
					@change="handleFileUploadAntigena()"
					type="file"
					name="file_antigena"
					id="file_antigena"
					ref="file_antigena"
					v-validate="'required|ext:pdf'"
					data-vv-as="'Certificado'" />
				<span v-show="errors.has('file_antigena')" class="form-group__error"><i class="fas fa-exclamation-circle"></i> {{ errors.first('file_antigena') }} </span>
			</div>
			<!--OBSERVACIONES -->
			<div class="col-md-12 form-group" v-if="state == 2">
				<label class="col-form-label label-modal">Recomendaciones</label>
				<textarea name="observation" v-model="observation" class="form-control" rows="2" v-validate="'required'" data-vv-as="'recomendaciones'"></textarea>
				<span v-show="errors.has('observation')" class="form-group__error"><i class="fas fa-exclamation-circle"></i> {{ errors.first('observation') }} </span>
			</div>
			<div class="col-md-12 form-group" v-if="propInfo.data.row.state && propInfo.data.row.suitable == 2">
				<label class="col-form-label label-modal d-block">Recomendaciones</label>
				<p class="mb-0" v-html="propInfo.data.row.observation"></p>
			</div>
		</div>

		<div class="row mt-5">
			<div class="col-12 d-flex justify-content-end">
				<button v-if="propInfo.data.row.state == 0" @click="mtd_send" class="btn btn-primary mr-3">{{ propInfo.data.buttonSubmit }}</button>
				<button @click="mtd_close" class="btn btn-secondary">Cancelar</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
	props: {
		propInfo: {
			required: true,
		},
		modal_id: {
			required: true,
		},
	},
	data() {
		return {
			state: null,
			state_antigena: null,
			observation: null,
			file: null,
			file_antigena: null,
			errorsBackend: {},
			methods: {
				init: {
					definition: 'this.mtd_onDefinition()',
				},
				action: {
					definition: 'this.mtd_store()',
				},
			},
		};
	},

	computed: {
		...mapGetters(['url_api', 'url_web']),
		cpd_btnDisabled() {
			return this.errors.any();
		},
	},
	mounted() {
		eval(this.methods.init[this.propInfo.data.method]);
	},
	methods: {
		...mapActions(['get', 'post', 'update', 'postWithFile']),
		mtd_onDefinition() {
			this.mtd_definition();
		},
		mtd_definition() {
			this.$emit('onload', { status: 'success' });
		},
		mtd_store: function () {
			this.$swal
				.fire({
					icon: 'warning',
					title: '¿Está seguro que desea enviar?',
					showCancelButton: true,
					confirmButtonText: 'Si, enviar',
				})
				.then(result => {
					if (result.isConfirmed) {
						this.formData = new FormData();
						this.formData.append('suitable', this.state);
						this.formData.append('observation', this.observation);
						this.formData.append('certificado', this.file);
						this.formData.append('state_antigena', this.state_antigena);
						this.formData.append('certificado_antigena', this.file_antigena);
						this.postWithFile({
							url: `${this.url_api}/medical_exam/${this.propInfo.data.row.id}/update`,
							formData: this.formData,
						}).then(res => {
							if (res.state == 'success') {
								this.$emit('success', {
									component: 'programming-results-modal',
									data: {},
									action: 'store',
									modal_id: 'programming-results-modal',
								});
								this.$swal.fire({
									icon: 'success',
									title: 'Enviado',
									text: `Resultados enviados correctamente.`,
									showCancelButton: false,
								});
							}
							if (res.state == 'exception') {
								window.simplealert('error', res.message, '<i class="fas fa-bomb"></i> Error interno');
							}
						});
					}
				});
		},
		$eval(expr) {
			return eval(expr);
		},
		mtd_close: function () {
			this.$emit('close');
		},
		mtd_send: function () {
			this.submitted = true;
			var _this = this;
			this.$validator.validate().then(valid => {
				if (valid) {
					_this.$eval(_this.methods.action[this.propInfo.data.method]);
				}
			});
		},
		handleFileUpload() {
			let file_new = this.$refs.file.files;
			this.file = file_new[0];
		},
		handleFileUploadAntigena() {
			let file_new = this.$refs.file_antigena.files;
			this.file_antigena = file_new[0];
		},
		mtd_donload: function () {
			window.open(`${this.url_web}/uploads/medicals/${this.propInfo.data.row.file}.pdf`);
		},
		mtd_donload_antigena: function () {
			window.open(`${this.url_web}/uploads/medicals/${this.propInfo.data.row.file_antigena}.pdf`);
		},
	},
};
</script>
